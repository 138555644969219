
import moment from "moment";



export default class HelperJS{



    formatMoney(value){

      value = Number(value);
      value = value.toFixed(2)
      return '$'+ value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    formatDate(date, type='short'){
      let dateIS = moment(date), dateReturn ='';
      if(type==='short'){

        dateReturn = dateIS.format('DD-MMM-YYYY')
      }
      if(type==='complete'){

        dateReturn = dateIS.format('DD MMMM YYYY')
      }

      if(type==='complete_hour'){

        dateReturn = dateIS.format('DD MMMM YYYY hh:mm:ss')
      }

      if(type==='database'){

        dateReturn = dateIS.format('YYYY-MM-DD');
      }


      return dateReturn;
    }
    convertToSlug(Text) {
      return Text.toLowerCase()
                 .replace(/ /g, '-')
                 .replace(/[^\w-]+/g, '');
    }
    compareArrayAdditional(firstArray, secondArray){
      let result = firstArray.filter(o => secondArray.some((item) => o.id === item.id));
      result.map(item=> item.status = true);
      return result
      }
      
    getActivesArray(element){
      let activas = element.filter(item=> item.status)
       activas = activas.map(item => item= item.id);
        return  activas;
    }

    async asyncForEach(array, callback){
    
       for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }

    }
    validateTypes (value, type){
      let result = false;
      if(type === 'required'){
        value = value+'';
        if(value!=='null' && value.trim().length > 0){
          result = true;
        }
      }
      if(type==='email'){
        let re = /\S+@\S+\.\S+/;
        result = re.test(value);
      }
      if(type==='minLenght'){
        if(value.length > 0){
          result = true;
        }
      }
      //console.log('result: '+type, result);
      return result;
    }

}
