export default class {

    constructor(obj = {}) {
        this.init(obj);
    }

  /**
  
   *
   * @param obj
   */
  init(obj = {}) {

       

        this.fillable = {
          id:null,
          arreglo_id: null,
          onServer: true,
          url: null,
          title: null
        };

        if (obj) {
            Object.keys(this.fillable).forEach( key => {
                this[ key ] = obj[ key ] || this.fillable[ key ];
            });
        }

    }   
}
